$aqua: #00c6d7;
$blue: #0099d8;
$green: #72bf44;
$pink: #cf2c91;
$red: #ef3f4a;
$yellow: #ffcb05;
$orange: #ff5800;
$fuschia: #cf2c91;
$purple: #8e52a1;

$default: #ffffff;
$body-bg: #eee;

$default-text: #000;
$content-text: $default-text;
$help-text: #949494;

$label-text: #ffffff;
$disabled: #dddddd;
$disabled-bg: tint($disabled, 15%);
$border: #d6d6d6;
$border-light: tint($border, 15%);
$input-border: shade($border, 5%);
$input-background: #ffffff;
$dropdown-text: #7d7d7d;

$topbar: #000;
$sidebar: #32363f;
$sidebar-text: #ffffff;
$sidebar-active: #1e2228;

$mail-box: whitesmoke;
$auth-panel-background: #ffffff;
$progress-background: rgba(#000000, 0.07);
$progress-default: rgba(#000000, 0.15);

$bootstrap-panel-radius: 0;
$bootstrap-panel-text: #7d7d7d;
$bootstrap-panel-bg: #ffffff;
$bootstrap-panel-header-bg: $bootstrap-panel-bg;
$bootstrap-panel-header-border: 1px solid rgba(0, 0, 0, 0.12);
$bootstrap-panel-shadow: none;

$primary: $aqua !default;
$info: $blue !default;
$success: $green !default;
$warning: $orange !default;
$danger: $red !default;
$pending: $yellow !default;

$primary-light: tint($primary, 30%);
$info-light: tint($info, 30%);
$success-light: tint($success, 30%);
$warning-light: tint($warning, 30%);
$danger-light: tint($danger, 30%);

$primary-dark: shade($primary, 15%);
$info-dark: shade($info, 15%);
$success-dark: shade($success, 15%);
$warning-dark: shade($warning, 15%);
$danger-dark: shade($danger, 15%);

$primary-bg: tint($primary, 20%);
$info-bg: tint($info, 20%);
$success-bg: tint($success, 20%);
$warning-bg: tint($warning, 20%);
$danger-bg: tint($danger, 20%);

$activelink: $blue;
$hoverlink: $blue;

@mixin body-bg() {
    background-color: $body-bg;
}
@mixin additional-bg() {
    //display: block; // additional background layer, hidden by default
}

$font-family: 'Montserrat', sans-serif;
$font-family-mono: 'Droid Sans Mono', monospace;

$resXXL: 1280px;
$resXL: 1170px;
$resL: 991px;
$resM: 768px;
$resS: 660px;
$resXS: 500px;
$resXXS: 435px;
$resMin: 320px;

$top-height: 66px;
$sidebar-width: 230px;

$small-card-height: 114px;
$xsmall-card-height: 187px;
$medium-card-height: 400px;
$extra-medium-card-height: 550px;
$large-card-height: 974px;

$default-animation-duration: 0.2s;
$default-animation-style: ease-out;

$assets-root: '/assets/';
$images-root: $assets-root + 'img/';
$fonts-root: $assets-root + 'fonts/';
$font-thin: 100;
$font-light: 200;
$font-normal: 400;
$font-bold: 600;
$font-bolder: 700;
$font-ultraBold: 800;

$facebook-color: #3b5998;
$twitter-color: #55acee;
$google-color: #dd4b39;
$linkedin-color: #0177B5;
$github-color: #6b6b6b;
$stackoverflow-color: #2F96E8;
$dribble-color: #F26798;
$behace-color: #0093FA;

$transition: 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);

$fa-font-path: "~font-awesome/fonts";
