@use '@angular/material' as mat;

@include mat.core();

$aqua-palette: mat.define-palette((
    50: rgba($aqua, 0.1),
    100: rgba($aqua, 0.2),
    200: rgba($aqua, 0.4),
    300: rgba($aqua, 0.6),
    400: rgba($aqua, 0.8),
    500: $aqua,
    600: $aqua,
    700: $aqua,
    800: $aqua,
    900: $aqua,
    A100: $aqua,
    A200: $aqua,
    A400: $aqua,
    A700: $aqua,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
        400: white,
        500: rgba(white, 0.87),
        600: rgba(white, 0.87),
        700: rgba(white, 0.87),
        800: rgba(white, 0.87),
        900: rgba(white, 0.87),
        A100: rgba(black, 0.87),
        A200: white,
        A400: white,
        A700: rgba(white, 0.87),
    )
));

$green-palette: mat.define-palette((
    50: rgba($green, .1),
    100: rgba($green, .2),
    200: rgba($green, .4),
    300: rgba($green, .6),
    400: rgba($green, .8),
    500: $green,
    600: $green,
    700: $green,
    800: $green,
    900: $green,
    A100: $green,
    A200: $green,
    A400: $green,
    A700: $green,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
        400: white,
        500: rgba(white, 0.87),
        600: rgba(white, 0.87),
        700: rgba(white, 0.87),
        800: rgba(white, 0.87),
        900: rgba(white, 0.87),
        A100: rgba(black, 0.87),
        A200: white,
        A400: white,
        A700: rgba(white, 0.87),
    )
));

$black-palette: mat.define-palette((
    50: rgba(#000, .1),
    100: rgba(#000, .2),
    200: rgba(#000, .4),
    300: rgba(#000, .6),
    400: rgba(#000, .8),
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #000,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
        400: white,
        500: rgba(white, 0.87),
        600: rgba(white, 0.87),
        700: rgba(white, 0.87),
        800: rgba(white, 0.87),
        900: rgba(white, 0.87),
        A100: rgba(black, 0.87),
        A200: white,
        A400: white,
        A700: rgba(white, 0.87),
    )
));

$red-palette: mat.define-palette((
    50: $danger,
    100: $danger,
    200: $danger,
    300: $danger,
    400: $danger,
    500: $danger,
    600: $danger,
    700: $danger,
    800: $danger,
    900: $danger,
    A100: $danger,
    A200: $danger,
    A400: $danger,
    A700: $danger,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
        400: white,
        500: rgba(white, 0.87),
        600: rgba(white, 0.87),
        700: rgba(white, 0.87),
        800: rgba(white, 0.87),
        900: rgba(white, 0.87),
        A100: rgba(black, 0.87),
        A200: white,
        A400: white,
        A700: rgba(white, 0.87),
    )
));

$black-theme: mat.define-light-theme($black-palette, $green-palette, $red-palette);
$aqua-theme: mat.define-light-theme($aqua-palette, $green-palette, $red-palette);

@include mat.core-theme($black-theme);
@include mat.autocomplete-theme($black-theme);
@include mat.button-theme($black-theme);
@include mat.button-toggle-theme($black-theme);
@include mat.card-theme($black-theme);
@include mat.checkbox-theme($black-theme);
@include mat.chips-theme($black-theme);
@include mat.datepicker-theme($black-theme);
@include mat.dialog-theme($black-theme);
@include mat.grid-list-theme($black-theme);
@include mat.icon-theme($black-theme);
@include mat.form-field-theme($black-theme);
@include mat.input-theme($black-theme);
@include mat.list-theme($black-theme);
@include mat.menu-theme($black-theme);
@include mat.progress-bar-theme($aqua-theme);
@include mat.progress-spinner-theme($black-theme);
@include mat.radio-theme($black-theme);
@include mat.select-theme($black-theme);
@include mat.sidenav-theme($black-theme);
@include mat.slide-toggle-theme($black-theme);
@include mat.slider-theme($black-theme);
@include mat.tabs-theme($black-theme);
@include mat.toolbar-theme($black-theme);
@include mat.tooltip-theme($black-theme);

// Font

[class^="mat-"] {
    font-family: $font-family !important;
}

// Input

.mat-form-field-label {
    text-transform: uppercase;
}

.mat-form-field {
    display: block !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.12);
}

.mat-form-field-appearance-legacy .mat-form-field-ripple {
    height: 1px !important;
}

.mat-form-field-subscript-wrapper {
    font-size: 11px;
}

.cdk-textarea-autosize {
    min-height: 36px;
}

.without-underline .mat-form-field-underline {
    display: none;
}

[floatLabel="never"] .mat-form-field-label {
    color: rgba(0, 0, 0, 0.38) !important;
    text-transform: none;
    font-style: italic;
}

[floatLabel="never"] .mat-input-element {
    caret-color: black;
}

// Legacy input

.error-message-legacy {
    display: none;
}

.has-error-legacy {
    position: relative;
}

.has-error-legacy .error-message-legacy {
    color: $danger;
    font-size: 11px;
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
}

.has-error-legacy .mat-form-field-label,
.has-error-legacy .mat-input-element:focus + .mat-form-field-label-wrapper .mat-form-field-label,
.has-error-legacy .mat-placeholder-required {
    color: $danger;
}

.has-error-legacy .mat-form-field-underline {
    background-color: $danger;
}

.has-error-legacy .mat-form-field-ripple {
    opacity: 0 !important;
}

.has-error-legacy .mat-form-field-subscript-wrapper {
    display: none;
}

// Slide toggle

.mat-slide-toggle-thumb {
    box-shadow: none !important;
}

.mat-slide-toggle-bar {
    width: 36px !important;
    height: 20px !important;
    border-radius: 13px !important;
}

.mat-slide-toggle-thumb-container {
    height: 20px !important;
    width: 20px !important;
    top: 0 !important;
    left: 0 !important;
}

.mat-slide-toggle-thumb {
    height: 14px !important;
    width: 14px !important;
    margin: 3px;
}

.mat-slide-toggle-bar {
    background-color: rgba(0, 0, 0, 0.24);
}

.mat-slide-toggle-ripple {
    height: 40px !important;
    width: 40px !important;
    top: -10px !important;
    left: -10px !important;
}

.mat-slide-toggle-content {
    font-weight: 400 !important;
    text-transform: none;
}

// Dialog

.cdk-overlay-pane {
    max-width: unset !important;

    &.theme-error .mat-dialog-container  {
        background-color: $danger;
        color: #fff;
    }
    &.theme-warning .mat-dialog-container {
        background-color: $warning;
        color: #fff;
    }
    &.theme-success .mat-dialog-container {
        background-color: $success;
        color: #fff;
    }
    &.theme-info .mat-dialog-container {
        background-color: $info;
        color: #fff;
    }
}

.mat-dialog-container {
    transform: none !important;
    padding: 0 !important;
    max-width: none !important;
    overflow: visible !important;
    border-radius: 0 !important;
}

.mat-menu-panel.disable-padding .mat-menu-content {
    padding: 0;
}

// Snack bar

snack-bar-container {
    border-radius: 0 !important;
    color: #fff;
    &.snack-bar-error {
        background-color: $danger;
    }
    &.snack-bar-warning {
        background-color: $warning;
    }
    &.snack-bar-success {
        background-color: $success;
    }
    &.snack-bar-info {
        background-color: $info;
    }
}

// Menu

.mat-menu-item {
    font-size: 14px !important;
    line-height: 42px !important;
    height: 42px !important;
    &.selected {
        color: $blue;
    }
}

// Chips

.mat-chip:not(.mat-basic-chip) {
    margin: 0 3px 3px 0 !important;
}

.mat-chip.mat-chip-selected:not(.mat-basic-chip).mat-primary {
    color: #fff;
}

.mat-chip.mat-chip-selected .mat-remove{
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 10px;
    cursor: pointer;
    svg {
        fill: #fff;
        display: block;
    }
}

.mat-select-panel,
.mat-menu-panel {
    transform: translateZ(0);
    background: #fff;
    border-radius: 0 !important;
    min-height: 0 !important;
}


// Override color so that invalid input is not error unless touched.
.mat-form-field-invalid.ng-untouched .mat-form-field-underline {
    border-color: #000;
}

.mat-form-field-invalid.ng-untouched .mat-form-field-ripple {
    background: #000;
}
.mat-form-field-invalid.ng-untouched .mat-form-field-label,
.mat-form-field-invalid.ng-untouched .mat-placeholder-required {
    color: #000;
}
