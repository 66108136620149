/**
 * Font
 */

ng2-smart-table * {
    font-family: inherit !important;
    font-weight: $font-normal !important;
}

/**
 * Table
 */

ng2-smart-table {
    table {
        color: inherit !important;
    }
    td,
    th {
        border-top: 1px solid #e9ebec;
        border-bottom: 1px solid #e9ebec;
    }
    td + td,
    th + th {
        padding-left: 0 !important;
    }
    th {
        padding: 14px 20px !important;
    }
    td {
        padding: 12px 20px !important;
    }
}

/**
 * Head
 */

.ng2-smart-title {
    font-size: 16px;
}

.ng2-smart-sort-link {
    color: $activelink !important;
}

/**
 * Rows
 */

.ng2-smart-row.selected {
    background: transparent !important;
}

/**
 * Cells
 */

ng2-smart-table table-cell-view-mode > div > div {
    white-space: pre;
}

/**
 * Actions
 */

.ng2-smart-actions-title-add,
.ng2-smart-actions {
    white-space: nowrap;
}

.ng2-smart-action-edit-edit {
    @extend .btn;
    @extend .btn-small;
    @extend .btn-primary;
    color: #fff !important;
    margin-right: 10px !important;
}

.ng2-smart-action-delete-delete {
    @extend .btn;
    @extend .btn-small;
    @extend .btn-danger;
    color: #fff !important;
}

.ng2-smart-action-price-list {
    @extend .btn;
    @extend .btn-small;
    @extend .btn-dropdown;
    width: 100%;
    color: #fff !important;
    text-align: left;
}

.ng2-smart-action-custom-custom {
    @extend .btn;
    @extend .btn-small;
    @extend .btn-primary;
    color: #fff !important;
}

/**
 * Filter
 */

.ng2-smart-filter {
    input,
    select {
        height: 32px !important;
        min-width: 140px;
    }
    ::-webkit-input-placeholder {
        color: #fff !important;
    }
    ::-moz-placeholder {
        color: #fff !important;
    }
    :-ms-input-placeholder {
        color: #fff !important;
    }
    :-moz-placeholder {
        color: #fff !important;
    }
}

/**
 * Pagination
 */

.ng2-smart-pagination {
    display: flex !important;
}

.ng2-smart-pagination-nav {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 16px;
}

.ng2-smart-page-item {
    margin-right: 14px;
}

.ng2-smart-page-link {
    border: 0 !important;
    border-radius: 0 !important;
    padding: 3px 9px !important;
    background-color: #32363f !important;
    color: #fff !important;
    transition: all $transition;
    text-decoration: none !important;
    line-height: 18px;
    &:hover {
        background-color: $primary !important;
    }
}

.ng2-smart-page-item.active .ng2-smart-page-link {
    background-color: $primary !important;
    &:hover {
        background-color: $primary !important;
    }
}

.ng2-smart-page-item.disabled {
    opacity: 0.2;
}
