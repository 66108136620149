.dropdown-item {
    line-height: 1;
    &.active, 
    &:active {
        background-color: #f7f7f9;
    }
}

.dropdown-menu {
    font-size: inherit;
}
