$hover: 24;

.btn {
    display: inline-block;
    cursor: pointer;
    border-radius: 0;
    padding: 0.9375rem 1.375rem;
    font-size: 0.9375rem;
    text-transform: uppercase;
    white-space: nowrap;
    transition: all $transition;
    &:hover {
        text-decoration: none !important;
    }
    &:focus {
        box-shadow: none !important;
    }
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
        opacity: 0.4;
        pointer-events: none;
    }
}

.btn-small {
    padding: 5px 8px;
    font-size: 12px;
    line-height: 12px;
}

@mixin openDropdownButton($color, $dark-color) {
    background: $color;
    border-color: darken($color, $hover);
    background-color: $dark-color;
    border-color: $dark-color;
}

.open > .btn.dropdown-toggle {
    &.btn.btn-primary {
        @include openDropdownButton($primary, $primary-dark)
    }
    &.btn-success {
        @include openDropdownButton($success, $success-dark)
    }
    &.btn-info {
        @include openDropdownButton($info, $info-dark)
    }
    &.btn-warning {
        @include openDropdownButton($warning, $warning-dark)
    }
    &.btn-danger {
        @include openDropdownButton($danger, $danger-dark)
    }
}

@mixin opaqueButton($color) {
    background-color: $color;
    border-color: $color;
    color: #fff;
    &:focus {
        background-color: darken($color, 10%);
        border-color: darken($color, 10%);
    }
    &:hover,
    &:active,
    &:target {
        background-color: #000;
        border-color: #000;
        color: #fff;
    }
}

@mixin translucentButton($color) {
    background-color: transparent;
    border-color: $color;
    color: $color;
    &:focus {
        border-color: darken($color, 10%);
        color: darken($color, 10%);
    }
    &:hover,
    &:active,
    &:target {
        background-color: transparent;
        border-color: #000;
        color: #000;
    }
}

.btn.btn-primary {
    @include opaqueButton($primary);
}

.btn.btn-secondary {
    @include translucentButton($primary);
}

.btn.btn-danger {
    @include opaqueButton($danger);
}

.btn.btn-info {
    @include opaqueButton($info);
}

.btn.btn-success {
    @include opaqueButton($success);
}

.btn.btn-warning {
    @include opaqueButton($warning);
}

.btn.btn-pending {
    @include opaqueButton($pending);
}

.btn.btn-danger.btn-secondary {
    @include translucentButton($danger);
}

.btn.btn-info.btn-secondary {
    @include translucentButton($info);
}

.btn.btn-success.btn-secondary {
    @include translucentButton($success);
}

.btn.btn-warning.btn-secondary {
    @include translucentButton($warning);
}

.btn.btn-pending.btn-secondary {
    @include translucentButton($pending);
}

.btn-with-icon {
    i {
        margin-right: 10px;
    }
}

.btn-group,
.btn-toolbar {
    :hover {
        transform: none;
    }
}

.btn-group {
    .dropdown-menu {
        margin-top: 0px;
    }
}

.btn-toolbar {
    display: inline-block;
}

.btn-small.btn-dropdown {
    position: relative;
    padding-right: 25px;
    .caret {
        position: absolute;
        right: 8px;
        top: 50%;
        display: inline-block;
        border-color: #fff transparent transparent transparent;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        height: 0;
        width: 0;
        margin-top: -3px;
        margin-left: 5px;
        vertical-align: middle;
    }
}

@mixin progressButtonColor($btnColor) {
    border-radius: 0;
    .content {
        &:after,
        &:before {
            color: darken($btnColor, 40);
        }
    }
    &.progress-button-style-move-up,
    &.progress-button-style-slide-down {
        .content {
            background-color: darken($btnColor, 10);
        }
    }
    &.progress-button-style-lateral-lines .progress-inner {
        border-color: darken($btnColor, 10);
        background: 0 0;
    }
    .progress {
        background-color: darken($btnColor, 10);
        box-shadow: 0 1px 0 darken($btnColor, 10);
    }
    .progress-inner {
        background-color: darken($btnColor, 20);
    }
    &.progress-button-perspective {
        background: none;
        .content {
            background-color: $btnColor;
        }
    }
}

button.progress-button {
    .progress {
        margin-bottom: 0;
        border-radius: 0;
    }
    &:hover {
        transform: none;
    }
    &.progress-button-style-shrink.btn.disabled.progress-button-dir-horizontal:hover {
        transform: scaleY(.3);
    }
    &.progress-button-style-shrink.btn.disabled.progress-button-dir-vertical:hover {
        transform: scaleX(.1);
    }
    &.btn.btn-primary {
        @include progressButtonColor($primary);
    }
    &.btn.btn-default {
        @include progressButtonColor($default);
    }
    &.btn.btn-success {
        @include progressButtonColor($success);
    }
    &.btn.btn-info {
        @include progressButtonColor($info);
    }
    &.btn.btn-warning {
        @include progressButtonColor($warning);
    }
    &.btn.btn-danger {
        @include progressButtonColor($danger);
    }
}

.btn-raised {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.35);
}

.btn-mm {
    padding: 5px 11px;
    font-size: 13px;
}

.btn-xm {
    padding: 8px 14px;
    font-size: 16px;
}

.btn-group-xs > .btn,
.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
}

.btn-group-sm > .btn,
.btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.btn-group-lg > .btn,
.btn-lg {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
}

.dropdown button.btn.btn-default.dropdown-toggle {
    color: $default-text;
    border: 1px solid $border;
    background-color: transparent;
    &:focus,
    &:active {
        background-color: $default;
    }
}

.ng2,
.blur {
    .dropdown button.btn.btn-default.dropdown-toggle {
        &:focus,
        &:active {
            background-color: transparent;
        }
    }
}

.bootstrap-select {
    .dropdown-toggle:focus {
        outline: none !important;
    }
    button.btn-default:focus {
        color: $default;
    }
    .btn {
        transition: none;
    }
}
