
.dl-horizontal {
    dt {
        float: left;
        width: 160px;
        overflow: hidden;
        clear: left;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    dd {
        margin-left: 160px + 20px;
    }
}

.dl-horizontal-85 {
    @extend .dl-horizontal;
    dt {
        width: 85px;
    }

    dd {
        margin-left: 85px + 20px;
    }
}

.dl-horizontal-95 {
    @extend .dl-horizontal;
    dt {
        width: 95px;
    }

    dd {
        margin-left: 95px + 20px;
    }
}

.dl-horizontal-125 {
    @extend .dl-horizontal;
    dt {
        width: 125px;
    }

    dd {
        margin-left: 125px + 20px;
    }
}
