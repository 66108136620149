@mixin svg-icon($url, $width:'', $height:'') {
    display: inline-block;
    background: url($url) no-repeat center;
    background-size: contain;
    vertical-align: middle;
    @if ($width !='') {
        width: $width + px;
    }
    @if ($height !='') {
        height: $height + px;
    }
}

@mixin svg-icon-class($iconName, $width:'', $height:'') {
    .#{'i-' + $iconName} {
        @include svg-icon($images-root + $iconName + '.svg', $width, $height);
    }
}

@include svg-icon-class('face', 80, 80);
@include svg-icon-class('money', 80, 80);
@include svg-icon-class('person', 80, 80);
@include svg-icon-class('refresh', 80, 80);
@mixin png-icon($url, $width, $height) {
    display: inline-block;
    width: $width + px;
    height: $height + px;
    background: url($url) no-repeat center center;
    background-size: $width + px $height + px;
}

@mixin png-icon-class($iconName, $width, $height) {
    .#{'i-' + $iconName} {
        @include png-icon($images-root + $iconName + '.png', $width, $height);
    }
}

//@include icon-png-class('arrival-icon', 11, 11);
