.top-title-bar {
    padding-top: 13px;
    padding-bottom: 27px;

    h1 {
        font-weight: 800;
        color: #000;
        float: left;
        width: auto;
        margin: 0;
        padding: 0;
        font-size: 26px;
        text-transform: uppercase;
        opacity: 0.9;
    }

    .market-notice {
        float: right;
        opacity: 0.6;
        margin-top: 6px;
    }
}
