.statuses .status {
    margin-right: 30px;
}

.status {
    i {
        margin-right: 5px;
    }
}

.status-none {
    color: #999;
}
.status-error {
    color: $danger;
}
.status-pending {
    color: $pending;
}
.status-success {
    color: $success;
}