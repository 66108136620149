.striped-header {
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 16px 20px;
    background: linear-gradient(-45deg, #f7f7f7 25%, transparent 25%, transparent 50%, #f7f7f7 50%, #f7f7f7 75%, transparent 75%, transparent);
    background-size: 6px 6px;

    &.light {
        padding: 8px 20px;
        font-size: 12px;
    }
}
