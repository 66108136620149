.modal-header {
    padding: 45px 40px;
    background-color: $primary;
    h4 {
        font-size: 26px;
        font-weight: $font-bolder;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        @media screen and (min-width: $resS) {
            white-space: pre-line;
        }
    }
}

.modal-progress {
    min-height: 5px;
}

.modal-body {
    position: static;
    padding: 40px 40px 0 40px;
    overflow: auto;
}

.modal-footer {
    border: none;
    padding: 40px;
    > :not(:first-child) {
        margin-left: 0.35rem;
    }
    > :not(:last-child) {
        margin-right: 0.35rem;
    }
}

.dialog-danger,
.dialog-alert,
.dialog-fullscreen,
.dialog-sm,
.dialog-md,
.dialog-lg {
    display: flex;
    flex-direction: column;
}

.dialog-danger,
.dialog-alert,
.dialog-sm,
.dialog-md,
.dialog-lg {
    width: calc(100vw - 60px) !important;
    max-height: calc(100vh - 60px) !important;
    @media screen and (max-width: $resXS) {
        width: 100vw !important;
        height: 100vh !important;
        max-height: none !important;
    }
}

.dialog-sm,
.dialog-md,
.dialog-lg {
    // IE11
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        height: calc(100vh - 60px) !important;
    }
}

.dialog-sm {
    max-width: 500px;
}

.dialog-md {
    max-width: 680px;
}

.dialog-lg {
    max-width: 800px;
}

.dialog-danger,
.dialog-alert {
    max-width: 600px;
    text-align: center;
    .modal-body span {
        display: block;
        white-space: pre-wrap;
    }
    .modal-footer {
        justify-content: center;
    }
    .btn {
        background-color: transparent;
        color: #fff;
        &:hover {
            border-color: #fff;
        }
    }
}

.dialog-danger {
    background-color: $danger;
    color: #fff;
}

.dialog-fullscreen {
    width: calc(100vw - 60px) !important;
    height: calc(100vh - 60px) !important;
    @media screen and (max-width: $resXS) {
        width: 100vw !important;
        height: 100vh !important;
    }
    .modal-body {
        padding: 0;
    }
    .dialog-sub-heading {
        display: block;
        font-size: 16px;
        font-weight: $font-bolder;
        padding: 20px 40px;
        text-transform: uppercase;
    }
}

.dialog-padded .modal-body {
    padding: 40px 40px 0 40px;
}
