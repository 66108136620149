/**
 * Pagination
 */

ngb-pagination {
    .pagination {
        display: flex;
    }

    .page-item .page-link {
        border: 0;
        border-radius: 0 !important;
        padding: 3px 9px;
        background-color: #32363f !important;
        color: #fff !important;
        transition: all $transition;
        text-decoration: none;
        margin-right: 14px;
    }

    .page-item .page-link:hover,
    .page-item.active .page-link {
        background-color: $primary !important;
        color: #fff !important;
    }

    .page-item.disabled .page-link {
        background-color: #32363f !important;
        color: #fff !important;
        opacity: 0.2;
    }
}

ngb-datepicker {
    .ngb-dp-header {
        padding: 20px 10px;
        border-bottom: 1px solid #cbcbcb;
        select + select {
            margin-left: 5px;
        }
    }
    .ngb-dp-content {
        width: 280px;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    select {
        border-radius: 0;
        border: 1px solid #cbcbcb;
    }
    .ngb-dp-navigation-chevron {
        border-width: 0.15em 0.15em 0 0;
    }
    [ngbDatepickerDayView] {
        border-radius: 50%;
    }
}

ngb-timepicker {
    .visually-hidden {
        display: none;
    }
    .ngb-tp {
        justify-content: center;
    }
    .ngb-tp-chevron:before {
        border-width: 0.15em 0.15em 0 0;
        height: 0.5em;
        width: 0.5em;
        left: 0.02em;
    }
    .form-control {
        padding: 0.375em 0.75em;
        font-size: 14px;
    }
    fieldset {
        border-top: 1px solid #cbcbcb;
        padding: 4px 0;
    }
    button {
        padding: 0.6rem 1rem !important;
    }
}
